import React, { useEffect, useState } from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import EmployeeData from "./EmployeeData";
import RetributiveData from "./RetributiveData";
import ContractData from "./ContractData";
import InternalEntitiesData from "./InternalEntitiesData";

const Cadastre = () => {
  const [userData, setUserData] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl}/api/cadastre/user/data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUserData(data.userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);//

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <CommonHeaderWithMenu title="Cadastre" />
      <div className="p-4 mb-16 ">
        <div className="flex justify-center mb-4">
          {userData.IMAGE_URL && (
            <img
              src={userData.IMAGE_URL}
              alt="User Profile"
              className="w-32 h-32 rounded-full border-4 border-gray-300"
            />
          )}
        </div>
        <div className="bg-white shadow-lg rounded-lg overflow-hidden border">
          <table className="table-auto w-full text-left">
            <tbody>
              <tr className="bg-gray-100">
                <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                  Name
                </th>
                <td className="px-4 py-3 text-gray-900">{userData.NOME}</td>
              </tr>
              <tr>
                <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                  Gender
                </th>
                <td className="px-4 py-3 text-gray-900">{userData.GENERO}</td>
              </tr>
              <tr className="bg-gray-100">
                <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                  Date of Birth
                </th>
                <td className="px-4 py-3 text-gray-900">
                  {userData.DT_NASCIMENTO}
                </td>
              </tr>
              <tr>
                <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                  Marital Status
                </th>
                <td className="px-4 py-3 text-gray-900">
                  {userData.ESTADO_CIVIL}
                </td>
              </tr>
              <tr className="bg-gray-100">
                <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                  Email
                </th>
                <td className="px-4 py-3 text-gray-900">{userData.EMAIL}</td>
              </tr>
              <tr>
                <th className="px-4 py-3 text-gray-700 text-sm uppercase tracking-wide">
                  Phone Number
                </th>
                <td className="px-4 py-3 text-gray-900">
                  {userData.TELEMOVEL_1}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <EmployeeData />
        <RetributiveData />
        <ContractData />
        <InternalEntitiesData />
      </div>
      <FooterNavigation />
    </>
  );
};

export default Cadastre;
